<!--  -->
<template>
  <div>
    <div class="content">
      <div class="content-form" style="width: 100%">
        <div class="content-info">
          <div class="form-ul">
            <div
              v-for="(item, index) in fileds"
              :key="index"
              :style="{
                width:
                  item.tagIcon === 'row'
                    ? '100%'
                    : item.tagIcon === 'table'
                    ? '100%'
                    : '50%',
              }"
              class="form-li-warp"
              v-if="item.look"
            >
              <div class="form-li" v-if="item.tag === 'el-upload'">
                <div>{{ item.label }}</div>
                <div
                  v-for="(fileItem, fileIndex) in getValue(item)"
                  :key="fileItem.uid"
                >
                  <div class="li-value">
                    <div class="file-list">
                      <div>{{ fileItem.name }}</div>
                      <el-button type="text" @click="down(fileItem)"
                        >查看下载</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.tag === 'el-editor'" class="form-li">
                <div>{{ item.label }}</div>
                <div v-html="getValue(item)" class="li-value"></div>
              </div>
              <div
                v-else-if="item.tagIcon === 'row'"
                class="form-li"
                style="width: 100%"
              >
                <div>{{ item.label }}</div>
                <el-table
                  :data="getValue(item)"
                  style="width: 100%; height: 100%"
                  max-height="500"
                  :header-cell-style="{
                    background: '#F0F4FA',
                    color: '#303133',
                    borderRadius: '4px',
                  }"
                >
                  <el-table-column
                    type="index"
                    width="55"
                    label="序号"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    v-for="(itemChild, indexChild) in item.child"
                    :key="indexChild"
                    :label="itemChild.label"
                    align="center"
                    v-if="itemChild.look"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="itemChild.tag !== 'el-upload'"
                        class="row-class"
                      >
                        <div v-if="itemChild.tag === 'el-sign'">
                          <img
                            style="height: 32px"
                            :src="
                              getTabsTabelValue(
                                scope.row,
                                itemChild,
                                scope.$index
                              )
                            "
                          />
                        </div>
                        <div v-else>
                          {{
                            getTabsTabelValue(
                              scope.row,
                              itemChild,
                              scope.$index
                            )
                          }}
                        </div>
                      </div>

                      <div class="upload-list" v-else>
                        <div
                          style="color: #409eff; cursor: pointer"
                          v-for="(uploadItem, uploadIndex) in getTabsTabelValue(
                            scope.row,
                            itemChild,
                            scope.$index
                          )"
                          :key="uploadIndex"
                          @click="fileDown(uploadItem)"
                        >
                          {{ uploadItem.name }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div
                class="form-li"
                v-else-if="item.tagIcon === 'table'"
                style="width: 100%"
              >
                <div style="margin-bottom: 15px">{{ item.label }}</div>
                <el-tabs
                  type="border-card"
                  v-model="activeName"
                  style="width: 100%"
                >
                  <el-tab-pane
                    :label="itemTab.name"
                    :name="itemTab.name"
                    v-for="(itemTab, indexTab) in getTabs(item)"
                    :key="indexTab"
                  >
                    <div class="tabs">
                      <div
                        class="tabs-ul"
                        v-for="(itemChild, indexChild) in itemTab.child"
                        :key="indexChild"
                        :style="{
                          width:
                            itemChild.tagIcon === 'row'
                              ? '100%'
                              : itemChild.tagIcon === 'table'
                              ? '100%'
                              : '50%',
                        }"
                        v-if="itemChild.look"
                      >
                        <div
                          class="tabs-li"
                          v-if="itemChild.tag === 'el-upload'"
                        >
                          <div class="li-title">{{ itemChild.label }}</div>
                          <div
                            class="li-upload-text"
                            v-for="(uploadItem2, uploadIndex2) in getTabsValue(
                              itemChild
                            )"
                            :key="uploadIndex2"
                          >
                            <div>{{ uploadItem2.name }}</div>
                            <el-button type="text" @click="down(uploadItem2)"
                              >查看下载</el-button
                            >
                          </div>
                        </div>
                        <div
                          v-else-if="itemChild.tagIcon === 'Editor'"
                          class="tabs-li"
                        >
                          <div>{{ item.label }}</div>
                          <div
                            v-html="getTabsValue(itemChild)"
                            class="li-value"
                          ></div>
                        </div>
                        <div
                          v-else-if="itemChild.tagIcon === 'row'"
                          class="tabs-li"
                        >
                          <el-table
                            :data="getTabsValue(itemChild)"
                            max-height="500"
                            :header-cell-style="{
                              background: '#F0F4FA',
                              color: '#303133',
                              borderRadius: '4px',
                            }"
                          >
                            <el-table-column
                              type="index"
                              width="55"
                              label="序号"
                              align="center"
                            >
                            </el-table-column>
                            <el-table-column
                              v-for="(
                                tableColumn, indexColumn
                              ) in itemChild.child"
                              :key="indexColumn"
                              :label="tableColumn.label"
                              :prop="tableColumn.fileds"
                              align="center"
                              v-if="tableColumn.look"
                            >
                              <template slot-scope="scope">
                                <div
                                  v-if="tableColumn.tag !== 'el-upload'"
                                  class="row-class"
                                >
                                  <div v-if="tableColumn.tag === 'el-sign'">
                                    <img
                                      style="height: 32px"
                                      :src="
                                        getTabsTabelValue(
                                          scope.row,
                                          tableColumn,
                                          scope.$index
                                        )
                                      "
                                    />
                                  </div>
                                  <div v-else>
                                    {{
                                      getTabsTabelValue(
                                        scope.row,
                                        tableColumn,
                                        scope.$index
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="upload-list" v-else>
                                  <div
                                    style="color: #409eff; cursor: pointer"
                                    v-for="(
                                      uploadItem, uploadIndex
                                    ) in getTabsTabelValue(
                                      scope.row,
                                      tableColumn,
                                      scope.$index
                                    )"
                                    :key="uploadIndex"
                                    @click="fileDown(uploadItem)"
                                  >
                                    {{ uploadItem.name }}
                                  </div>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                        <div class="tabs-li" v-else>
                          <div class="li-title">{{ itemChild.label }}</div>
                          <div class="li-text">
                            <div v-if="itemChild.tagIcon === 'Sign'">
                              <img
                                style="height: 32px"
                                :src="getTabsValue(itemChild)"
                              />
                            </div>
                            <div v-else>
                              {{ getTabsValue(itemChild) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
                <div></div>
              </div>
              <div class="form-li" v-else>
                <div>{{ item.label }}</div>
                <div class="li-value">
                  <div v-if="item.tag === 'el-sign'">
                    <img style="height: 32px" :src="getValue(item)" />
                  </div>
                  <div v-else>{{ getValue(item) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="print-btn">
        <el-button type="primary" @click="printSubmit">打印</el-button>
      </div>
    </div>
    <iframe :src="pdfSrc" style="display: none"></iframe>
  </div>
</template>

<script>
import moment from "moment";
import {
  geteWorkFlowsRecord,
  getAppFormView,
  geteWorkFlowsDetail,
  getAppFormDataDetail,
  postRestartadd,
  getFieldPermission,
  getWithdraw,
} from "@/api/app";
import { mapGetters } from "vuex";
import lodash from "lodash";
import formStart from "@/components/firmStart/formSubmit.vue";
import { getPrintPdf, getPrintWordPdf } from "@/api/print";
export default {
  data() {
    return {
      pdfSrc: "",
      fileds: [],
      valueData: [],
      activeName: "",
      flowRecord: [],
      //流程权限
      formData: {},
      show: false,
      //节点详情
      // nodeDetai: {},
      datas: {},
      oldEditData: {},
      // 撤回按钮是否展示
      withdrawBtn: false,
      printObj: {
        asyncUrl(reslove, vue) {
          getPrintPdf(this.formId, this.printTemplateId).then((res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            let pdfSrc = window.URL.createObjectURL(blob);

            reslove(pdfSrc);
          });
        },
        previewBeforeOpenCallback(vue) {
          console.log("正在加载预览窗口");
        },
        previewOpenCallback(vue) {
          console.log("已经加载完预览窗口");
        },
        beforeOpenCallback(vue) {
          console.log("打开之前");
        },
        openCallback(vue) {
          console.log("执行了打印");
        },
        closeCallback(vue) {
          console.log("关闭了打印工具");
        },
      },
    };
  },
  props: {
    formId: {
      type: String,
    },
    printId: {
      type: String,
    },
    printTemplateId: {
      type: String,
    },
    formDetailId: {
      type: String,
    },
    appId: {
      type: String,
    },
    printType: {
      type: String,
    },
    printWordType: {
      type: String,
    },
  },
  components: {
    formStart,
  },
  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
  methods: {
    printSubmit() {
      if (this.printType === "excel") {
        getPrintPdf(this.formId, this.printTemplateId, this.printId).then(
          (res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            this.pdfSrc = window.URL.createObjectURL(blob);
            const iframe = this.$el.querySelector("iframe");
            const innerWindow =
              iframe.contentWindow || iframe.contentDocument.parentWindow;
            console.log(this.pdfSrc, iframe, innerWindow);
            setTimeout(() => {
              innerWindow.print();
            }, 500);
          }
        );
      } else {
        window.open(
          `${window.location.origin}/api/wordtemp/renderTemplate?formId=${this.formId}&templateId=${this.printTemplateId}&form_detail_id=${this.printId}`
        );
      }
    },
    getFormattedTimeDiff(addTime, sendTime) {
      addTime = moment(addTime, "YYYY-MM-DD HH:mm:ss").unix();
      sendTime = moment(sendTime, "YYYY-MM-DD HH:mm:ss").unix();
      var startTimestamp = addTime; // 开始时间戳，单位为秒
      var endTimestamp = sendTime; // 结束时间戳，单位为秒
      var startMoment = moment.unix(startTimestamp);
      var endMoment = moment.unix(endTimestamp);

      var duration = moment.duration(endMoment.diff(startMoment));
      var diffDays = Math.floor(duration.asDays());
      var diffHours = duration.hours();
      var diffMinutes = duration.minutes();
      var diffSeconds = duration.seconds();

      var formattedDiff = "";
      if (diffDays > 0) {
        formattedDiff += diffDays + " 天 ";
      }
      if (diffHours > 0) {
        formattedDiff += diffHours + " 小时 ";
      }
      if (diffMinutes > 0) {
        formattedDiff += diffMinutes + " 分钟 ";
      }
      if (diffSeconds > 0) {
        formattedDiff += diffSeconds + " 秒";
      }
      return formattedDiff;
    },
    userNameArray(val) {
      // 将item.user_name转换为数组
      return val ? val.split(",") : [];
    },
    menberCheckDict(data) {
      let arr = [];
      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    departCheckDict(data) {
      let arr = [];
      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },

    getTabsTabelValue(row, item, index) {
      for (let i in row) {
        if (item.tag === "departCheck") {
          if (item.fileds === i) {
            return this.departCheckDict(row[i]);
          }
        } else if (item.tag === "menberCheck") {
          if (item.fileds === i) {
            if (row[i]) {
              return this.menberCheckDict(row[i]);
            }
          }
        } else if (item.tag === "addr") {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        } else if (
          item.tag === "el-checkbox-group" ||
          item.tag === "el-cascader"
        ) {
          if (item.fileds === i) {
            return row[i].join("/");
          }
        } else if (item.tag === "el-upload") {
          if (item.fileds === i) {
            return row[i];
          }
        } else if (item.tag === "el-select") {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        } else if (item.tag === "datePicker") {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("至");
            } else {
              return row[i];
            }
          }
        } else {
          if (item.fileds === i) {
            return row[i];
          }
        }
      }
    },
    getTabsValue(item) {
      let obj = this.data.data;
      let row = "";
      for (let i in obj) {
        if (i.includes("tabs")) {
          row = obj[i];
        }
      }
      if (item.tag === "menberCheck") {
        for (let i in row) {
          if (item.fileds === i) {
            let arr = [];
            if (row[i].length > 0) {
              return this.menberCheckDict(row[i]);
            }
          }
        }
      } else if (item.tag === "departCheck") {
        for (let i in row) {
          if (item.fileds === i) {
            if (row[i].length > 0) {
              return this.departCheckDict(row[i]);
            }
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i];
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "el-select") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "datePicker") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i];
          }
        }
      }
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
    down(fileItem) {
      if (process.env.NODE_ENV === "development") {
        window.open(
          "http://123.249.127.106:8787/api/upload/handleRequest?attachmen_id=" +
            fileItem.response.data.attachment_id
        );
      } else {
        window.open(
          `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
            fileItem.response.data.attachment_id
        );
      }
    },
    fileDown(fileItem) {
      if (process.env.NODE_ENV === "development") {
        window.open(
          "http://123.249.127.106:8787/api/upload/handleRequest?attachmen_id=" +
            fileItem.response.data.attachment_id
        );
      } else {
        window.open(
          `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
            fileItem.response.data.attachment_id
        );
      }
    },
    getTabs(item) {
      this.activeName = item.child[0].name;
      return item.child;
    },
    getTableValue(row, item) {
      for (let i in row) {
        for (let j in row[i]) {
          if (item.tag === "departCheck") {
            if (item.fileds === j) {
              return this.departCheckDict(row[i][j]);
            }
          } else if (item.tag === "menberCheck") {
            if (item.fileds === j) {
              if (row[i][j]) {
                return this.menberCheckDict(row[i][j]);
              }
            }
          } else if (item.tag === "addr") {
            if (item.fileds === j) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join("/");
              }
            }
          } else if (
            item.tag === "el-checkbox-group" ||
            item.tag === "el-cascader"
          ) {
            if (item.fileds === j) {
              return row[i][j].join("/");
            }
          } else if (item.tag === "el-upload") {
            if (item.fileds === j) {
              // let arr = [];
              // row[i][j].forEach((item) => {
              //   arr.push(item.name);
              // });

              return row[i][j];
            }
          } else if (item.tag === "el-select") {
            if (item.fileds === j) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join(",");
              } else {
                return row[i][j];
              }
            }
          } else {
            if (item.fileds === j) {
              return row[i][j];
            }
          }
        }
      }
    },
    getValue(item) {
      let obj = this.data.data;

      if (item.tagIcon === "row") {
        for (let i in obj) {
          if (i === item.fileds) {
            return obj[i];
          }
        }
      } else if (item.tag === "menberCheck") {
        for (let i in obj) {
          if (i === item.fileds) {
            return this.menberCheckDict(obj[i]);
          }
        }
        return "departCheck";
      } else if (item.tag === "departCheck") {
        for (let i in obj) {
          if (i === item.fileds) {
            return this.departCheckDict(obj[i]);
          }
        }
        return "departCheck";
      } else if (item.tag === "el-cascader") {
        for (let i in obj) {
          if (i === item.fileds) {
            return obj[i].join("/");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in obj) {
          if (i === item.fileds) {
            if (Array.isArray(obj[i])) {
              return obj[i].join("/");
            }
          }
        }
      } else if (item.tag === "el-select") {
        for (let i in obj) {
          if (i === item.fileds) {
            if (Array.isArray(obj[i])) {
              return obj[i].join(",");
            } else {
              return obj[i];
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in obj) {
          if (i === item.fileds) {
            return obj[i].join(",");
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in obj) {
          if (i === item.fileds) {
            return obj[i];
          }
        }
      } else if (item.tagIcon === "table") {
        return "标签页";
      } else {
        for (let i in obj) {
          if (i === item.fileds) {
            return obj[i];
          }
        }
      }
    },

    getList() {
      getAppFormDataDetail({
        app_id: this.appId,
        form_id: this.formId,
        id: this.printId,
      }).then((res) => {
        if (res.code == 200) {
          this.datas = res.data;
          let datas2 = res.data.data;
          this.data = res.data;
          this.fileds = res.data.head;
          for (let i in datas2) {
            if (i.slice(0, 2) == "vw") {
              this.oldEditData[i] = datas2[i];
            }
          }
        }
      });
    },

    // 同步表单数据
    isLookAndEdit() {
      getFieldPermission(this.formId).then((res) => {
        if (res.code == 200) {
          let new_arr = lodash.cloneDeep(this.fileds);

          let basicfield_arr = [];
          res.data.forEach((item) => {
            if (item.tagIcon == "table") {
              item.child.forEach((item2) => {
                item2.child.forEach((item3) => {
                  if (item3.tagIcon == "row") {
                    let obj = {
                      fileds: item3.fileds,
                      label: item3.label,
                      edit: item3.edit,
                      look: item3.look,
                      tag: item3.tag,
                      tagIcon: item3.tagIcon,
                    };
                    basicfield_arr.push(obj);
                    item3.child.forEach((item4) => {
                      basicfield_arr.push(item4);
                    });
                  } else {
                    basicfield_arr.push(item3);
                  }
                });
              });
            } else if (item.tagIcon == "row") {
              let obj = {
                fileds: item.fileds,
                label: item.label,
                edit: item.edit,
                look: item.look,
                tag: item.tag,
                tagIcon: item.tagIcon,
              };
              basicfield_arr.push(obj);
              if (item.child.length > 0) {
                item.child.forEach((item2) => {
                  basicfield_arr.push(item2);
                });
              }
            } else {
              basicfield_arr.push(item);
            }
          });
          new_arr.forEach((item, index) => {
            let islook = false;
            basicfield_arr.forEach((bas_item) => {
              if (item.fileds == bas_item.fileds) {
                item.look = bas_item.look;
                item.edit = bas_item.edit;
              }
              if (item.tagIcon == "table") {
                item.child.forEach((item2) => {
                  let islooktab = false;
                  item2.child.forEach((item3) => {
                    if (item3.fileds == bas_item.fileds) {
                      item3.look = bas_item.look;
                      item3.edit = bas_item.edit;
                    }
                    if (item3.tagIcon == "row") {
                      item3.child.forEach((item4) => {
                        if (item4.fileds == bas_item.fileds) {
                          item4.look = bas_item.look;
                          item4.edit = bas_item.edit;
                          if (bas_item.look) {
                            islooktab = true;
                          }
                        }
                      });
                      item3.look = islooktab;
                    }
                  });
                });
              } else if (item.tagIcon == "row") {
                item.child.forEach((item2) => {
                  if (item2.fileds == bas_item.fileds) {
                    item2.look = bas_item.look;
                    item2.edit = bas_item.edit;
                    if (bas_item.look) {
                      islook = true;
                    }
                  }
                });
                item.look = islook;
              }
            });
          });

          this.fileds = new_arr;
        }
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.content {
  height: 100%;

  width: 100%;

  overflow-y: scroll;

  .close {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
    z-index: 9999;

    i {
      font-size: 16px;
      font-weight: bold;
      color: #535866;
    }
  }
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  height: 90vh;
  padding: unset !important;
}

::v-deep .el-dialog {
  position: absolute;
  bottom: 0;
  margin: unset !important;
  height: 90vh;
}

::v-deep .el-dialog__wrapper {
  overflow: unset !important;
}

.content-form {
  width: calc(100vw - 455px);

  .btnBox {
    position: fixed;
    bottom: 30px;
    left: 50px;
  }

  .form-title {
    width: 100%;
    position: fixed;
    height: 60px;
    z-index: 999;
    background-color: #fff;
    line-height: 60px;
    display: flex;
    box-sizing: border-box;
    padding-left: 24px;
    border-bottom: 1px solid #e4e7ed;

    .info-icon {
      width: 24px;
      background-color: red;
      border-radius: 3px;
      text-align: center;
      line-height: 24px;
      height: 24px;

      i {
        color: #fff;
      }
    }

    .name {
      font-size: 14px;
      margin-left: 10px;
      font-weight: bold;
      color: #303133;
    }

    .title-info {
      display: flex;
      align-items: center;
    }
  }

  .flow-title {
    display: flex;
    align-items: center;
    line-height: unset !important;

    .title-name {
      box-sizing: border-box;
      padding: 0px 16px;
      font-size: 14px;
      margin-left: 10px;
      line-height: 20px;

      font-weight: 400;
      color: #535866;
      border-left: 1px solid #e4e7ed;
      height: 18px;
      border-right: 1px solid #e4e7ed;
    }

    .title-status {
      margin-left: 10px;
      line-height: 20px;

      font-weight: 400;
      color: #49c48c;

      height: 18px;
    }
  }

  .content-info {
    padding: 24px 24px 24px 24px;
    box-sizing: border-box;
  }

  .form-ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    .form-li-warp {
      margin-bottom: 16px;
      max-width: 100%;
      min-width: 50%;
    }

    .file-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      box-sizing: border-box;
    }

    .form-li {
      width: 100%;

      .li-value {
        width: 95%;
        margin-top: 20px;
        background: #f5f6f8;
        border-radius: 2px;
        color: #141e31;
        font-size: 14px;
        box-sizing: border-box;
        min-height: 32px;
        padding: 6px 8px;
        margin-bottom: 10px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tabs-ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      box-sizing: border-box;
    }

    .tabs-li {
      margin-bottom: 16px;
      width: 100%;

      .li-text {
        width: 95%;
        margin-top: 20px;
        background: #f5f6f8;
        border-radius: 2px;
        color: #141e31;
        font-size: 14px;
        box-sizing: border-box;
        min-height: 32px;
        padding: 6px 8px;
        margin-bottom: 10px;
      }

      .li-upload-text {
        width: 95%;
        background: #f5f6f8;
        border-radius: 2px;
        color: #141e31;
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 10px;
        box-sizing: border-box;
        height: 32px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.content-flow {
  width: 455px;
  position: absolute;
  right: 0;
  top: 20px;
  background: #f5f8fc;
  padding: 50px 30px 20px 30px;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;

  .flow {
    width: 100%;

    .item {
      position: relative;

      .dot {
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #409eff;
        margin-right: 8px;

        .dot_son {
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #409eff;
          margin-left: 3px;
          margin-top: 3px;
        }
      }

      .dot_header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #303133;
          line-height: 22px;
        }
      }

      .flow_line {
        position: absolute;
        top: 20px;
        left: 9px;
        width: 1px;
        height: 100%;
        background-color: #409eff;
      }

      .main {
        width: 93%;
        // height: 170px;
        margin-left: 25px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 16px 24px;
        box-shadow: 0px 0px 8px 0px #ccc;
        border-radius: 4px;
        margin-bottom: 10px;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #303133;
          line-height: 22px;
        }

        .tou {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: #409eff;
          border-radius: 50%;
          display: inline-block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          font-style: normal;
          text-align: center;
        }

        .state {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #49c48c;
          line-height: 28px;
        }

        .state.yellow {
          color: #ffa42f;
        }

        .state.blue {
          color: #409dff;
        }

        .state.pink {
          color: #ff5cfd;
        }

        .state.red {
          color: #ff5f5f;
        }

        .main_item {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #303133;
          line-height: 22px;

          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #303133;
          }
        }
      }
    }
  }
}

.row-class {
  padding: 10px 0;
  box-sizing: border-box;
}

::v-deep .btn {
  bottom: -40px;
}
.print-btn {
  border-top: 1px dashed #bcbcbc;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 111;
  justify-content: center;
}
</style>
