<template>
  <el-input
    v-if="
      data.__config__.tagIcon == 'input' ||
      data.__config__.tagIcon == 'password'
    "
    v-model="data.__config__.defaultValue"
    :placeholder="data.placeholder"
    :maxlength="data.maxlength"
    :show-word-limit="data['show-word-limit']"
    :readonly="data.readonly"
    :disabled="data.disabled"
    :clearable="data.clearable"
    :prefix-icon="data['prefix-icon']"
    :suffix-icon="data['suffix-icon']"
    :style="data.style"
    :show-password="data['show-password']"
    @input="sendValue"
  >
    <template slot="prepend" v-if="data.__slot__.prepend">{{
      data.__slot__.prepend
    }}</template>
    <template slot="append" v-if="data.__slot__.append">{{
      data.__slot__.append
    }}</template>
  </el-input>

  <el-input
    v-else-if="data.__config__.tagIcon == 'textarea'"
    :type="data.__config__.tagIcon"
    v-model="data.__config__.defaultValue"
    :placeholder="data.placeholder"
    :maxlength="data.maxlength"
    :show-word-limit="data['show-word-limit']"
    :readonly="data.readonly"
    :disabled="data.disabled"
    :style="data.style"
    :autosize="data.autosize"
    @change="sendValue"
  >
  </el-input>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "MyInput",
  data() {
    return {};
  },

  components: {},
  mounted() {
    this.sendValue();
  },
  created() {
    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
    if (this.data.valueType === "formula") {
      this.$bus.$emit("formula", this.data);
    }
  },
  methods: {
    sendValue() {
      let obj = {
        name: this.data.__vModel__,

        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
  },
};
</script>

<style  scoped>
</style>
