<!--  -->
<template>
  <div>
    <el-drawer
      :visible.sync="show"
      direction="ltr"
      width="20%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="content">
        <div class="title">
          <div style="cursor: pointer" class="title-icon" @click="goHome">
            <i class="iconfont icon-shouyefill"></i>

            <div>工作台</div>
          </div>
          <div class="close-icon">
            <i
              class="iconfont icon-31guanbi"
              @click="handleClose"
              style="cursor: pointer"
            ></i>
          </div>
        </div>
        <div class="search">
          <el-autocomplete
            placeholder="输入名称来搜索"
            v-model="name"
            style="flex: 1"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            @select="handleSelect"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
            </template>
          </el-autocomplete>
        </div>
        <div class="app-list">
          <div class="list-title">我的应用</div>
          <!-- <el-tree :data="groupList" :props="defaultProps"> -->
          <!-- <span class="custom-tree-node" slot-scope="{ node, data }"> -->
          <div class="list-ul">
            <div
              class="list-li"
              :class="[
                index === active ? 'list-li-active' : 'list-li',
                'list-li',
              ]"
              v-for="(item, index) in data"
              :key="index"
              @click="onChange(item, index)"
            >
              <div class="li-icon" :style="{ background: item.color }">
                <i :class="'iconfont-1 ' + item.icon"></i>
              </div>
              <div class="li-name">{{ item.name }}</div>
              <div class="line"></div>
            </div>
          </div>
          <!-- </span> -->
          <!-- </el-tree> -->
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getAppList } from "@/api/app";
export default {
  data() {
    return {
      data: [],
      active: null,
      name: "",
      groupList: [],
      appList: [],
      defaultProps: {
        children: "appList",
        label: "name",
      },
    };
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    getAppList().then((res) => {
      console.log(res);
      this.data = res.data.rows;

      this.groupList = res.data.app_group_list;
      this.appList = res.data.rows;
      if (this.groupList.length == 0) {
        this.groupList.push({
          name: "其他",
          group_no: 0,
          appList: [],
        });
        this.appList.forEach((item, index) => {
          if (item.group_no === 0) {
            this.groupList[0].appList.push(item);
          }
        });
      } else {
        this.groupList.forEach((item) => {
          this.$set(item, "appList", []);
          this.appList.forEach((item2) => {
            if (item.group_no == item2.group_no) {
              item.appList.push(item2);
            }
          });
        });
      }
    });
  },
  methods: {
    querySearch(queryString, cb) {
      this.restaurants = [];
      var restaurants = this.data;

      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.$router.replace({
        path: "/todolist",
        query: {
          id: item._id,
        },
      });
      this.$router.go(0);
      this.$store.dispatch("app/setAppName", JSON.stringify(item));
    },
    handleClose() {
      this.$emit("handleClose");
    },
    goHome() {
      this.$emit("handleClose");
      this.$router.push("/home");
    },
    onChange(item, index) {
      if (this.$route.query.id === item._id) {
        this.$emit("handleClose");
      } else {
        this.$router.replace({
          path: "/todolist",
          query: {
            id: item._id,
          },
        });
        this.$router.go(0);
        this.$store.dispatch("app/setAppName", JSON.stringify(item));
      }
      // this.active = index;
      // this.$emit("handleClose");
    },
    goApp(item) {},
  },
  created() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer {
  width: 13% !important;
}

::v-deep .el-tree > .el-tree-node {
  border: none;
  padding-left: 20px;
}

.content {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-sizing: border-box;

    .title-icon {
      width: 96px;
      height: 36px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      background: #f0f4fa;
      border-radius: 18px;

      i {
        color: #409eff;

        margin-right: 5px;
      }

      div {
        font-size: 14px;

        font-weight: bold;
        color: #409eff;
      }
    }
  }

  .search {
    padding: 24px 12px;
    box-sizing: border-box;
  }

  .app-list {
    .list-title {
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: bold;
      padding: 0 24px;
      box-sizing: border-box;
      color: #303133;
    }

    .list-ul {
      .list-li:hover {
        background: #f0f4fa;

        .li-name {
          font-weight: bold;
          color: #409eff;
        }
      }

      .list-li-active {
        background: #f0f4fa;
        position: relative;

        .li-name {
          font-weight: bold !important;
          color: #409eff !important;
        }

        .line {
          width: 4px;
          height: 24px;
          background: #409eff;
          position: absolute;
          top: 4px;
          right: 5px;
          border-radius: 2px;
        }
      }

      .list-li {
        padding: 0 24px;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;
        align-items: center;

        .li-icon {
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border-radius: 4px;
          margin-right: 12px;

          i {
            color: #fff;
          }
        }

        .li-name {
          font-size: 14px;
          font-weight: 400;
          color: #303133;
        }
      }
    }
  }
}
</style>
