import {
  getAppAllMemberList,
  getAppAllDepartList
} from "@/api/app"
const state = {
  routeName: "",
  leftNav: {
    type: localStorage.getItem("type") ? localStorage.getItem("type") : 0,
    listIndex: localStorage.getItem("listIndex") ?
      localStorage.getItem("listIndex") : 0,
  },
  communicateNav: 0,

  formInfo: localStorage.getItem("formInfo") ? localStorage.getItem("formInfo") : {},

  myBacklogTotal: 0,

  appName: localStorage.getItem("appName") || "",
  allMenberList: [],
  allDepartList: [],
  handValue: {
    name: "",
    value: ""
  },
  isClickForm: "",
  dragData: "",
  dragStatus: "3"
};

const mutations = {
  SET_ROUTE_NAME: (state, name) => {
    state.routeName = name;
  },
  SET_LEFT_NAV: (state, navObj) => {
    state.leftNav.type = navObj.type;
    state.leftNav.listIndex = navObj.listIndex;
    localStorage.setItem("type", navObj.type);
    localStorage.setItem("listIndex", navObj.listIndex);
  },
  SET_COMMUNICATE_NAV: (state, communicateNav) => {
    state.communicateNav = communicateNav;
  },
  SET_CLEAR_LEFT_NAV: (state) => {
    state.leftNav.type = 0;
    state.leftNav.listIndex = 0;
    localStorage.setItem("type", "0");
    localStorage.setItem("listIndex", "0");
  },
  SET_FORM_INFO: (state, formInfo) => {
    state.formInfo = formInfo
    localStorage.setItem("formInfo", JSON.stringify(formInfo))
  },

  SET_BACKLOG_TOTAL: (state, num) => {
    state.myBacklogTotal = num
  },

  SET_APP_NAME: (state, appName) => {
    state.appName = appName
    localStorage.setItem("appName", appName)
  },
  SET_ALL_MENBER: (state, allMenberList) => {
    state.allMenberList = allMenberList
  },
  SET_ALL_DEPART: (state, allDepartList) => {
    state.allDepartList = allDepartList
  },
  SET_HANDLE_VALUE: (state, handValue) => {
    state.handValue = handValue
  },
  SET_IS_CLICK_FORM: (state, obj) => {
    state.isClickForm = obj
  },
  SET_DRAG_DATA: (state, dragData) => {
    state.dragData = dragData

  },
  SET_DRAG_STATUS: (state, dragStatus) => {
    state.dragStatus = dragStatus
  }
};

const actions = {
  setRouteName({
    commit
  }, name) {
    commit("SET_ROUTE_NAME", name);
  },
  setLeftNav({
    commit
  }, navObj) {
    commit("SET_LEFT_NAV", navObj);
  },
  setCommunicateNav({
    commit
  }, communicateNav) {
    commit("SET_COMMUNICATE_NAV", communicateNav);
  },
  setClearLeftNav({
    commit
  }) {
    commit("SET_CLEAR_LEFT_NAV");
  },

  logoutAction({
    commit
  }, payload) {
    commit("setTokenMutation", "");
  },
  setFormInfo({
    commit
  }, formInfo) {
    commit("SET_FORM_INFO", formInfo)
  },

  setBacklogTotal({
    commit
  }, num) {
    commit("SET_BACKLOG_TOTAL", num)
  },

  setAppName({
    commit
  }, appName) {
    commit("SET_APP_NAME", appName)
  },
  setAllMenber({
    commit
  }) {
    getAppAllMemberList("all").then(res => {
      commit("SET_ALL_MENBER", res.data)
    })
  },
  setAllDepart({
    commit
  }) {
    getAppAllDepartList().then(res => {
      commit("SET_ALL_DEPART", res.data)
    })
  },
  setHandValue({
    commit
  }, handValue) {
    commit("SET_HANDLE_VALUE", handValue)
  },
  setIsClickForm({
    commit
  }, obj) {
    commit("SET_IS_CLICK_FORM", obj)
  },
  updateDragData({
    commit
  }, data) {
    commit("SET_DRAG_DATA", data)
  },
  setDragStatus({
    commit
  }, status) {
    commit("SET_DRAG_STATUS", status)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
