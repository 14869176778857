<!--  -->
<template>
  <div>
    <div class="depart-radio">选择部门</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
  created() {},
};
</script>
<style lang='scss' scoped>
.depart-radio {
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  font-size: 14px;
  line-height: 32px;
  text-indent: 10px;
  font-weight: 400;
  color: #c0c4cc;
}
</style>
