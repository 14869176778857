<!--  -->
<template>
  <div>
    <div class="title">
      {{ data.__config__.rowTitle }}
    </div>

    <el-table
      :data="tableData"
      style="margin-bottom: 10px"
      border
      ref="myTable"
      :header-cell-class-name="starAdd"
      :header-cell-style="{ background: '#F8F8F9' }"
    >
      <el-table-column
        type="index"
        width="100"
        align="center"
        label="序号"
        fixed="left"
      >
        <template slot-scope="scope">
          <div class="operate">
            <div class="operate-index">
              {{ scope.$index + 1 }}
            </div>
            <div class="operate-btn" v-if="rule.delete || rule.add">
              <i
                v-if="rule.delete"
                class="el-icon-delete"
                @click="del(scope.row, scope.$index)"
              ></i>
              <el-popover
                placement="bottom"
                width="140"
                trigger="click"
                :ref="`popoverBtn-${scope.$index}`"
              >
                <div
                  class="list-ul"
                  v-for="(item, index) in addTableData"
                  :key="index"
                >
                  <div
                    class="list-li"
                    @click="handleAddBtn(scope.$index, scope, index)"
                  >
                    {{ item }}
                  </div>
                </div>
                <i class="el-icon-more" slot="reference" v-if="rule.add"></i>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :prop="item.__vModel__"
        v-for="(item, index) in data.__config__.children"
        width="260"
        :label="item.__config__.label"
        align="center"
        v-if="item.__config__.isDisplay"
      >
        <template
          slot-scope="scope"
          v-if="
            (tableData[scope.$index][item.__vModel__]['isshow'] !== false ||
              tableData[scope.$index][item.__vModel__]['isshow'] ===
                undefined) &&
            tableData[scope.$index][item.__vModel__].isDisplay
          "
        >
          <Module-screen
            :module-list="item"
            @tableValue="handleValue"
            :index="scope.$index"
            :formId="formId"
            :appId="appId"
            :draftData="scope.row[item.__vModel__]"
          ></Module-screen>
        </template>
      </el-table-column>

      <!-- <el-table-column align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="del(scope.row, scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-button @click="add" type="primary" v-if="rule.add">添加</el-button>
  </div>
</template>

<script>
import ModuleScreen from "./../tableComponents/moduleScreen.vue";
import { getTableRule } from "@/api/app";
export default {
  data() {
    return {
      tableData: [],
      isDrat: false,
      formulaName: "",
      addTableData: ["复制到下一行", "复制到最后一行"],
      rule: {
        add: true,
        insert: true,
        edit: true,
        delete: true,
      },
    };
  },
  name: "Table",
  components: {
    ModuleScreen,
  },
  props: {
    data: {
      type: Object,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    tableRuleType: {
      type: String,
    },
    flowRecord: {
      type: Array,
    },
  },
  watch: {
    tableData: {
      handler(val) {
        let obj = {
          name: this.data.__config__.componentName,
          value: val,
        };

        this.$emit("value", obj);
      },
      deep: true,
    },
  },
  created() {
    if (this.tableRuleType === "1") {
      getTableRule(this.formId, "").then((res) => {
        res.data.forEach((item) => {
          if (item.fileds === this.data.__config__.componentName) {
            this.rule = item.rule;
          }
        });
      });
    }

    if (
      this.tableRuleType === "2" &&
      this.flowRecord &&
      this.flowRecord.length > 0
    ) {
      let setup_id = this.flowRecord[this.flowRecord.length - 1].step_id;
      getTableRule(this.formId, setup_id).then((res) => {
        res.data.forEach((item) => {
          if (item.fileds === this.data.__config__.componentName) {
            this.rule = item.rule;
          }
        });
      });
    }
  },
  mounted() {
    setTimeout(() => {
      if (
        this.data.__config__.defaultValue &&
        this.data.__config__.defaultValue.length > 0
      ) {
        this.tableData = this.data.__config__.defaultValue;

        this.tableData.forEach((item, index) => {
          if (!this.rule.edit) {
            this.$set(itemA, "disabled", true);
          }
          this.data.__config__.children.forEach((itemA) => {
            if (item[itemA.__vModel__]) {
              item[itemA.__vModel__] = {
                value: item[itemA.__vModel__].value
                  ? item[itemA.__vModel__].value
                  : "",
                required: itemA.__config__.required,
                label: itemA.__config__.label,
                keys: itemA.__vModel__,
                isDisplay: itemA.__config__.isDisplay,
                tag: itemA.__config__.tag,
                disabled: this.rule.edit ? itemA.disabled : true,
              };
            } else {
              item[itemA.__vModel__] = {
                value: "",
                required: itemA.__config__.required,
                label: itemA.__config__.label,
                keys: itemA.__vModel__,
                isDisplay: itemA.__config__.isDisplay,
                tag: itemA.__config__.tag,
                disabled: this.rule.edit ? itemA.disabled : true,
              };
            }
          });
        });
      } else {
        let obj = {};

        this.data.__config__.children.forEach((itemA) => {
          if (!this.rule.edit) {
            this.$set(itemA, "disabled", true);
          }
          obj[itemA.__vModel__] = {
            value: "",
            required: itemA.__config__.required,
            label: itemA.__config__.label,
            keys: itemA.__vModel__,
            isDisplay: itemA.__config__.isDisplay,
            tag: itemA.__config__.tag,
            disabled: this.rule.edit ? itemA.disabled : true,
          };
        });

        this.tableData.push(obj);
      }
    }, 500);
    this.$bus.$on("tableXygz", (data, xygzParent, index) => {
      data.forEach((item) => {
        let arr = item.value;
        if (xygzParent === this.data.__config__.componentName) {
          this.tableData.forEach((item2, index2) => {
            if (index === index2) {
              for (let i in item2) {
                if (arr.includes(item2[i].label)) {
                  this.$set(item2[i], "isshow", item.isshow);
                }
                if (arr.includes(item2[i].label)) {
                  this.$set(item2[i], "required", true);
                } else {
                  this.$set(item2[i], "required", false);
                }
              }
            }
          });
        }
      });
    });
    setTimeout(() => {
      this.$bus.$on("setTableFormula", (data) => {
        this.tableData.forEach((item, index) => {
          for (let i in item) {
            if (item[i].keys === data.vModel) {
              if (this.formulaName !== data.vModel) {
                if (data.type === "JOIN" || data.type === "TEXTLOCATION") {
                  this.$set(item[i], "value", data.str);
                } else {
                  if (data.str.length === this.tableData.length) {
                    this.$set(item[i], "value", data.str[index]);
                  } else {
                    this.$set(item[i], "value", data.str[0]);
                  }
                }
              }
            }
          }
        });
        this.$forceUpdate();
      });
    }, 1500);
  },

  methods: {
    handleAddBtn(scopeIndex, scope, index) {
      if (index === 0) {
        this.tableData.splice(
          scopeIndex + 1,
          0,
          JSON.parse(JSON.stringify(scope.row))
        );
      } else if (index === 1) {
        this.tableData.push(JSON.parse(JSON.stringify(scope.row)));
      }
      // else {
      //         let obj = {};
      //         this.data.__config__.children.forEach((itemA) => {
      //           obj[itemA.__vModel__] = {
      //             value: itemA.__config__.defaultValue,
      //             required: itemA.__config__.required,
      //             label: itemA.__config__.label,
      //             keys: itemA.__vModel__,
      //             isDisplay: itemA.__config__.isDisplay,
      //           };
      //         });
      //         if (index === 3) {
      //           this.tableData.splice(
      //             scopeIndex + 1,
      //             0,
      //             JSON.parse(JSON.stringify(obj))
      //           );
      //         } else if (index === 2) {
      //           this.tableData.splice(scopeIndex, 0, JSON.parse(JSON.stringify(obj)));
      //         }
      //       }
      this.$refs["popoverBtn-" + scopeIndex].doClose();
    },
    handleValue(val) {
      this.formulaName = val.name;
      this.$set(this.tableData[val.index][val.name], "value", val.value);
    },
    starAdd(obj) {
      let filed = obj.column.property;
      let dom = "";
      this.data.__config__.children.forEach((item) => {
        if (filed === item.__vModel__) {
          dom = item;
        }
      });
      if (dom && dom.__config__.required) {
        return "star";
      }
    },
    del(item, index) {
      this.tableData.splice(index, 1);
    },

    add() {
      let obj = {};
      this.data.__config__.children.forEach((itemA) => {
        if (!this.rule.edit) {
          this.$set(itemA, "disabled", true);
        }
        obj[itemA.__vModel__] = {
          value: itemA.__config__.defaultValue,
          required: itemA.__config__.required,
          label: itemA.__config__.label,
          keys: itemA.__vModel__,
          isDisplay: itemA.__config__.isDisplay,
          tag: itemA.__config__.tag,
          disabled: this.rule.edit ? itemA.disabled : true,
        };
      });
      this.tableData.push(obj);
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep table th.star div::after {
  content: "*";
  color: red;
}
::v-deep .el-table__fixed-right {
  // 10 是我这里全局定义的滚动条的高度
  height: 100% !important;
}
::v-deep .el-table__fixed-body-wrapper {
  height: calc(100% - 48px);
}
::v-deep .el-table__body {
  height: 100%;
}
.title {
  color: #303133;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
}
.operate {
  cursor: pointer;
}
.operate-index {
  display: block;
}
.operate-btn {
  display: none;
}
.operate:hover {
  .operate-index {
    display: none;
  }
  .operate-btn {
    display: block;
  }
}
.el-icon-more {
  transform: rotate(90deg);
  margin-left: 10px;
}
.el-icon-more:hover {
  color: #409eff;
}
.el-icon-delete:hover {
  color: red;
}
.list-li {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 10px;
}
.list-li:hover {
  background: #f0f4fa;
}
</style>
