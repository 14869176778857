<template>
  <el-input
    v-model="data.__config__.defaultValue"
    @focus="numberFocus"
    @blur="numberBlur"
    :disabled="data.disabled"
    :placeholder="data.placeholder"
    oninput="value=value.replace(/[^-?\d*\.?\d+$]/g, '')"
  ></el-input>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "MyInputNumber",
  data() {
    return {};
  },
  watch: {
    "data.defaultValue": {
      handler(val) {
        this.sendValue();
      },

      deep: true,
    },
  },
  components: {},
  created() {
    if (this.data.valueType === "formula") {
      this.$bus.$emit("formula", this.data);
    }
  },
  methods: {
    numberFocus() {
      if (this.data.__config__.defaultValue) {
        if (this.data.isThousandth) {
          this.data.__config__.defaultValue = this.rmoney(
            this.data.__config__.defaultValue
          );
        }
      }
    },
    numberBlur() {
      if (this.data.__config__.defaultValue) {
        if (this.data.isThousandth && !this.data.isFixed) {
          this.data.__config__.defaultValue = this.toThousands(
            this.data.__config__.defaultValue,
            0,
            true
          );
        }
        if (this.data.isFixed) {
          if (this.data.isThousandth) {
            this.data.__config__.defaultValue = this.toThousands(
              this.data.__config__.defaultValue,
              this.data.FixedNumber,
              true
            );
          } else {
            this.data.__config__.defaultValue = this.rmoney(
              this.data.__config__.defaultValue
            );
            this.data.__config__.defaultValue = Number(
              this.data.__config__.defaultValue
            ).toFixed(this.data.FixedNumber);
          }
        }
      }
      this.sendValue();
    },
    rmoney(s) {
      let str = "";
      if (s) {
        str = String(s).replace(/,/g, "");
      }
      if (s && (s + "").indexOf(".") > -1 && Number(str)) {
        return String(s).replace(/[^\d\.-]/g, "");
      } else if (s && Number(str)) {
        return str;
      } else {
        return s;
      }
    },

    toThousands(num, places, zeroFill = true) {
      if (typeof num === "string") num = Number(num);
      if (typeof num !== "number" || isNaN(num)) return;
      let numStr = String(num); // 数字字符串
      let [integer, decimal] = numStr.split("."); // 整数部分，小数部分
      // 小数部分处理：四舍五入保留 places 位小数
      // 注意：不能直接使用 toFixed，toFixed 它是一个四舍六入五成双的诡异的方法(也叫银行家算法)，"四舍六入五成双"，最好别用，否则可能会出大问题！
      if (decimal && decimal.length > places) {
        const placesBase = Math.pow(10, places);
        num = Math.round(num * placesBase) / placesBase;
        numStr = String(num);
        decimal = numStr.split(".")[1];
      }
      decimal = decimal ? decimal : "";
      if (places > decimal.length && zeroFill) {
        const fillZeroCount = places - decimal.length;
        for (let i = 0; i < fillZeroCount; i++) {
          decimal += "0";
        }
      }
      // 整数部分处理
      let thousandsIntStr = "";
      while (integer.length > 3) {
        thousandsIntStr = "," + integer.slice(-3) + thousandsIntStr;
        integer = integer.slice(0, integer.length - 3);
      }
      thousandsIntStr = integer ? integer + thousandsIntStr : thousandsIntStr;

      let result = decimal ? `${thousandsIntStr}.${decimal}` : thousandsIntStr;
      if (result.startsWith("-,")) result = result.replace("-,", "-");

      return result;
    },
    setValue() {
      if (this.data.valueType === "custom") {
      }
    },
    sendValue() {
      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
  },
};
</script>

<style  scoped>
</style>
