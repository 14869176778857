<!--  -->
<template>
  <div v-if="type === 'time'">
    <el-time-picker
      v-model="dateValue"
      :placeholder="placeholder"
      :value-format="format"
      :format="format"
      :disabled="!isDisabled"
    >
    </el-time-picker>
  </div>
  <div v-else-if="type === 'timerang'">
    <el-time-picker
      v-model="dateValue"
      :placeholder="placeholder"
      is-range
      :value-format="format"
      :format="format"
      range-separator="至"
    >
    </el-time-picker>
  </div>
  <div v-else-if="type === 'date'">
    <el-date-picker
      v-model="dateValue"
      :placeholder="placeholder"
      :type="
        format === 'yyyy' ? 'year' : format === 'yyyy-MM' ? 'month' : 'date'
      "
      :value-format="format"
      :format="format"
    >
    </el-date-picker>
  </div>
  <div v-else-if="type === 'daterang'">
    <el-date-picker
      v-model="dateValue"
      :placeholder="placeholder"
      type="daterange"
      :value-format="format"
      :format="format"
    >
    </el-date-picker>
  </div>
  <div v-else-if="type === 'datetimerang'">
    <el-date-picker
      v-model="dateValue"
      type="datetimerange"
      :value-format="format"
      :format="format"
      :placeholder="placeholder"
    >
    </el-date-picker>
  </div>
  <div v-else-if="type === 'datetime'">
    <el-date-picker
      v-model="dateValue"
      type="datetime"
      :value-format="format"
      :format="format"
      :placeholder="placeholder"
    >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateValue: this.value,
      type: "date",
    };
  },
  props: [
    "format",
    "__config__",
    "isDisabled",
    "config",
    "value",
    "placeholder",
  ],
  watch: {
    format(val) {
      console.log(val);
      if (this.format === "yyyy-MM" || this.format === "yyyy-MM-dd") {
        this.type = "date";
      } else {
        this.type = "datetime";
      }
      this.dateValue = "";
    },
    type(val) {
      this.dateValue = "";
    },
    dateValue(val) {
      this.$emit("input", val);
      this.$emit("datePicker", val);
    },
  },
  methods: {},
  mounted() {},
  created() {
    if (this.format === "yyyy-MM" || this.format === "yyyy-MM-dd") {
      this.type = "date";
    } else {
      this.type = "datetime";
    }
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-date-editor {
  width: 100%;
}
::v-deep .el-form-item--medium .el-form-item__content {
  line-height: 40px !important;
}
</style>
