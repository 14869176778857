var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moduleScreen"},[(_vm.moduleList.__config__.tag == 'el-input')?_c('my-input',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'inputNumber')?_c('my-input-number',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'el-select')?_c('my-select',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'el-cascader')?_c('my-cascader',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(
      _vm.moduleList.__config__.tag == 'el-radio-group' ||
      _vm.moduleList.__config__.tag == 'el-checkbox-group'
    )?_c('my-radio',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'datePicker')?_c('my-time',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'el-upload')?_c('my-upload',{attrs:{"data":_vm.moduleList,"formId":_vm.formId,"appId":_vm.appId},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'addr')?_c('my-addr',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'departCheck')?_c('my-depart',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tag == 'menberCheck')?_c('my-menber',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):(
      _vm.moduleList.__config__.tagIcon == 'row' &&
      _vm.moduleList.__config__.isDisplay
    )?_c('my-table',{attrs:{"data":_vm.moduleList,"formId":_vm.formId,"flowRecord":_vm.flowRecord,"tableRuleType":_vm.tableRuleType},on:{"value":_vm.handleValue}}):(_vm.moduleList.__config__.tagIcon == 'Line')?_c('my-line',{attrs:{"data":_vm.moduleList}}):(_vm.moduleList.__config__.tagIcon == 'Sign')?_c('my-sign',{attrs:{"data":_vm.moduleList}}):(_vm.moduleList.__config__.tagIcon == 'Position')?_c('my-position',{attrs:{"data":_vm.moduleList}}):(_vm.moduleList.__config__.tag === 'el-serial')?_c('my-serial',{attrs:{"data":_vm.moduleList}}):(_vm.moduleList.__config__.tag === 'el-editor')?_c('my-editor',{attrs:{"data":_vm.moduleList},on:{"value":_vm.handleValue}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }