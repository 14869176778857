import { render, staticRenderFns } from "./myEditor.vue?vue&type=template&id=64fd4048&scoped=true"
import script from "./myEditor.vue?vue&type=script&lang=js"
export * from "./myEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fd4048",
  null
  
)

export default component.exports